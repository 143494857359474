<template>
    <section class="main">
        <div class="container">
            <div class="drag-box">
                <div class="drag-box-item" ref="drag_box_item_pictures">
                    <div class="item-title">{{point=='pt'?'图片':'视频'}}集</div>
                     <draggable
                        :list="list"
                        class="list-group"
                        draggable=".drag-item"
                        @start="dragging = true"
                        @end="dragging = false"
                    >
                        <div class="drag-item"  v-for="item in list" :key="item.src">
                            <video  v-if="point=='av'?true:false" class='drag-object' :src="item.src" onerror="this.src = 'https://ae01.alicdn.com/kf/Hb6aba101d8ff4d17a7d0c5d7e349690dB.gif';this.onerror=null;"></video>
                            <el-image v-if="point=='pt'?true:false"
                                :class="'drag-object'"
                                fit="cover"
                                :src="item.src"
                                :data-src="item.src"
                                :preview-src-list="[item.src]">
                            </el-image>
                            <!-- <img  class="drag-object" :data-src="item.src" src="https://ae01.alicdn.com/kf/He2ac615faca74f3fba82050b09d2c36ch.gif" onerror="this.src = 'https://ae01.alicdn.com/kf/Hb6aba101d8ff4d17a7d0c5d7e349690dB.gif';this.onerror=null;" alt=""> -->
                            <div class="drag-delete-button" @click="removeItem(item)">x</div>
                            <el-input class="drag-object-text" v-model="item.text" placeholder="添加描述.."></el-input>
                        </div>
                        <div class="drag-add">
                            <svg @click.stop.right="v_if.addItemsVisible = true" @click="v_if.uploadVisible = true"  t="1564625226383" class="drag-object" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1980" width="200" height="200"><path d="M915.746268 0h-807.911811C48.273134 0 0 48.482772 0 107.834457v807.911811c0 59.561323 48.482772 107.834457 107.834457 107.834456h807.911811c59.561323 0 107.834457-48.482772 107.834456-107.834456v-807.911811c0-59.343622-48.482772-107.834457-107.834456-107.834457z m56.634456 915.746268c0 31.139276-25.495181 56.634457-56.634456 56.634456h-807.911811c-31.139276 0-56.634457-25.495181-56.634457-56.634456v-807.911811c0-31.139276 25.495181-56.634457 56.634457-56.634457h807.911811c31.139276 0 56.634457 25.495181 56.634456 56.634457v807.911811z m-267.288189-429.451087H537.293606V318.488189a25.688693 25.688693 0 0 0-25.704819-25.704819A25.688693 25.688693 0 0 0 485.875906 318.488189v167.806992H318.068913c-14.215055 0-25.704819 11.497827-25.704819 25.495181a25.688693 25.688693 0 0 0 25.704819 25.704819H485.875906v167.806992a25.688693 25.688693 0 0 0 25.704818 25.704819 25.688693 25.688693 0 0 0 25.704819-25.704819V537.495181H705.108661a25.688693 25.688693 0 0 0 25.704819-25.704819c0-14.206992-11.497827-25.495181-25.704819-25.495181z" fill="#1296db" p-id="1981"></path></svg>
                        </div>
                    </draggable>
                </div>
            </div>
        </div>
        <el-dialog title="选择文件" :append-to-body="true"  v-dialogDrag center :visible.sync="v_if.uploadVisible" width="80%">
            <el-upload
            list-type="picture-card"
            multiple
            ref="upload"
            :action="$constant.qiniuUrl"
            :data="qiniuParam"
            :file-list="data.fileList"
            :on-change="handleChange"
            :before-upload="beforeUpload"
            :on-success="handleSuccess"
            :auto-upload = "false">
            <i class="el-icon-plus"></i>
            </el-upload>
            <el-button type="primary" @click="commitFileList();">确 定</el-button>
        </el-dialog>
        <el-dialog  title="输入链接(*,*)" :append-to-body="true"  v-dialogDrag center :visible.sync="v_if.addItemsVisible" width="50%">
            <el-input v-model="data.addSrcItems" @change="addSrcItems(data.addSrcItems)"></el-input>
        </el-dialog>
    </section>
</template>

<script>
// import bus from '../common/bus';   
import draggable from 'vuedraggable'
// import lazyload from '../../utils/lazyload.js';
export default {
name: 'draglist',
props: {
    isEdit:{
    type: Boolean,
    default: true
    },
    object:{

    },
    point:{
    type: String,
    default: 'pt'
    },
    isAdmin: {
    type: Boolean,
    default: false
    },
    prefix: {
    type: String,
    default: ""
    },
    maxSize: {
    type: Number,
    default: 5
    },
    maxNumber: {
    type: Number,
    default: 5
    }
},
watch: {
    // 此处监听variable变量，当期有变化时执行
    isEdit(newVal, oldVal){
        if(newVal){
            return;
        }
        this.$emit("addPicture", this.returnObject());
    }
},
data() {
    return {
        dragOptions:{
            animation: 120,
            scroll: true,
            group: 'sortlist',
            ghostClass: 'ghost-style'
        },
        list:new Array(),
        data:{
            addSrcItems:"",
            fileList:[]
        },
        isClosed:!this.isEdit,
        v_if:{
            uploadVisible:false,
            addItemsVisible:false,
        },
        qiniuParam: {
            token: "",
            key: ""
        },
        dragging:true,
        cos:null,
    }
},
components:{
    draggable
},
created() {
    if ((!this.isAdmin && !this.$common.isEmpty(this.$store.state.currentUser)) || (this.isAdmin && !this.$common.isEmpty(this.$store.state.currentAdmin))) {
        this.getUpToken();
    }
},
mounted(){
    let list = new Array()
    this.object = JSON.parse(this.object);
    this.object?(this.object[0].forEach((element,index) => {
        list.push({src:element,text:this.object[1][index]})
    }),this.list = list):(this.list=new Array());
},
methods: {
    getUpToken() {
        this.$http.get(this.$constant.baseURL + "/qiniu/getUpToken", {}, this.isAdmin)
        .then((res) => {
            if (!this.$common.isEmpty(res.data)) {
            this.qiniuParam.token = res.data;
            }
        })
        .catch((error) => {
            this.$message({
            message: error.message,
            type: "error"
            });
        });
    },
    handleSuccess(response, file, fileList) {
        console.log(response);
        this.qiniuParam.key = "";
        let url = this.$constant.qiniuDownload + response.key;
        this.list.push({src:url,text:""});
        this.$common.saveResource(this, this.prefix, url, this.isAdmin);
    },
    handleChange(file, fileList) {
        let flag = false;
        // if (!/image\/\w+/.test(file.type)) {
        //   this.$message({
        //     message: "必须上传图片！",
        //     type: "warning"
        //   });
        //   flag = true;
        // }
        if (file.size > this.maxSize * 1024 * 1024) {
        this.$message({
            message: "图片最大为" + this.maxSize + "M！",
            type: "warning"
        });
        flag = true;
        }

        if (flag) {
        fileList.splice(fileList.size - 1, 1);
        }
    },
    beforeUpload(file) {
        if (this.$common.isEmpty(this.qiniuParam.token)) {
            this.$message({
                message: "上传出错！",
                type: "warning"
            });
            return false;
        }
        this.qiniuParam.key = this.prefix + "/" + (!this.$common.isEmpty(this.$store.state.currentUser.username) ? (this.$store.state.currentUser.username.replace(/[^a-zA-Z]/g, '') + this.$store.state.currentUser.id) : (this.$store.state.currentAdmin.username.replace(/[^a-zA-Z]/g, '') + this.$store.state.currentAdmin.id)) + new Date().getTime() + Math.floor(Math.random() * 1000);
    },
    removeItem(item){
        let index = this.list.indexOf(item);
        if(index==-1) return;
        this.list.splice(index,1);
    },
    returnObject(){
        let pics = new Array();let texts = new Array();
        this.list.forEach(element => {
            pics.push(element.src);
            texts.push(element.text);
        });
        return JSON.stringify([pics,texts]);
    },
    commitFileList(){
        this.$confirm('将上传文件？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(() => {
            let that = this;
            this.$refs.upload.submit();
        }).catch((e) => {
            this.$message.error(e);
        });
    },
    addSrcItems(items){
        items.split(",").forEach(element => {
            this.list.push({src:element,text:""});
        });
        this.data.addSrcItems = "";
    },
}
}

</script>

<style scoped>
    section.main .container{
        padding: 0px;
    }
    .drag-box{
        display: flex;
        user-select: none;
    }
    .drag-box-item {
        flex: 1;
        background-color: #eff1f5;
        border-radius: 6px;
        border: 1px #e1e4e8 solid;
        max-height:80vh;
        overflow-y: scroll;
        
    }
    .drag-box-item::-webkit-scrollbar{
        display: none;
    }
    .item-title{
        padding: 8px 8px 8px 12px;
        font-size: 14px;
        line-height: 1.5;
        color: #24292e;
        font-weight: 600;
    }
    .drag-item{
        display: inline-block;
        position: relative;
        border: 1px #e1e4e8 solid;
        padding: 5px;
        margin: 5px  8px;
        list-style: none;
        border: 1px solid #209eff3d;
        border-radius: 6px;
        cursor: pointer;
        -webkit-transition: border .3s ease-in;
        transition: border .3s ease-in;
        width: 160px;
        box-sizing: content-box;
    }
    .drag-add{
        display: inline-block;
        position: relative;
        padding: 5px;
        margin: 20px  10px;
        list-style: none;
        -webkit-transition: border .3s ease-in;
        transition: border .3s ease-in;
        width: 160px;
        height:100px;
    }
    .drag-add  svg{
        height:50% !important;
        width:50% !important;
        position: absolute;
        left:50%;
        top:50%;
        transform: translate(-50%,-50%);
    }

    .drag-add:hover svg path{
        fill:#d4237a;
        transition: .5s;
    }
    .drag-add path{
        transition:0.3s ;
        cursor:pointer;
        transition: .5s;
    }
    .drag-object-text{
        margin-top: 5px;
    }
    .drag-object {
        object-fit: cover;
        width: 160px;
        height:100px;
        display: block;
    }
    .drag-item .drag-delete-button{
        content: "×";
        width: 32px;
        height: 32px;
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 10;
        text-align: center;
        border-radius: 50%;
        line-height: 32px;
        font-size: 1.5em;
        color: #eee;
        -webkit-transition:  .3s ease-in;
        transition:  .3s ease-in;
        transform: translate(50%,-40%);
        background: #209eff3d;
        opacity: 0;
        pointer-events: all;
        cursor: pointer;
    }
    .drag-item:hover .drag-delete-button{
        -webkit-transition:  .3s ease-in;
        transition:  .3s ease-in;
        background: #20a0ff;
        opacity: 1;
    }
    .drag-item:hover {
        border: 1px solid #20a0ff;
    }
    .drag-title {
        font-weight: 400;
        line-height: 25px;
        margin: 10px 0;
        font-size: 22px;
        color: #1f2f3d;
    }
    .ghost-style{
        display: block;
        color: transparent;
        border-style: dashed
    }
</style>
